.light{
	color: #FFFFFF;
}
.dark{
	color: #000000;
}

h1{
    font-size: 3rem;
    line-height: 1.1;
}
p{
    line-height: 1.65;
    font-weight: 300;
}

.fw_900{
    font-weight: 900 !important;
}
.fw_800{
    font-weight: 800 !important;
}
.fw_700{
    font-weight: 700 !important;
}
.fw_600{
    font-weight: 600 !important;
}
.fw_500{
    font-weight: 500 !important;
}
.fw_400{
    font-weight: 400 !important;
}
.fw_300{
    font-weight: 300 !important;
}
.fw_200{
    font-weight: 200 !important;
}
.fw_100{
    font-weight: 100 !important;
}

@include media-breakpoint-down(xs) { 
    .extra-size{
        font-size: 2.5rem;
    }
}
@include media-breakpoint-down(sm) { 
}
@include media-breakpoint-down(md) { 
}
@include media-breakpoint-down(lg) { 
}
@include media-breakpoint-down(xl) { 
}