/*!

=========================================================
* Argon Design System React - v1.1.0 based on Argon Design System - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Bootstrap Functions
@import "../../../../node_modules/bootstrap/scss/functions";

// Theme Variables
@import "argon-design-system/variables";

$theme: #ff0000;
$primary: #ff0000;
$secondary: #0a4d97;
$tertiary: #732222;
$whatsapp: #1ebea5;
$theme-colors: (
  "theme": $theme,
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "whatsapp": $whatsapp,
  "light": #f4f6f8,
);

// Bootstrap Core
@import "../../../../node_modules/bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Theme Core
@import "argon-design-system/theme";

// React Differences
@import "react/react-differences";

// Custom utilities
@import "utilities/helper";
@import "utilities/fonts";
@import "utilities/rewrites";

.bg-light {
    background-color: #f4f6f8 !important;
}

h1, h2, h3, h4, h5{
    font-family: "Libre Franklin", Helvetica, Arial, sans-serif;
    color: #424242;
}

div.topM{
    >div.overlay{
        background-color: rgba(#000000, .6);
        @include absolute_center();
    }
    position: relative;
    margin-top: 88px;
}

div.detail_add{
    >div.modal-content{
        >div.modal-header{
            >h5{

            }
            >button{
                >span{

                }
            }
        }
        >div.modal-body{
            img{
                width: 220px;
                max-width: 100%;
            }
        }
    }
}

div.title_one{
    display: inline-block;
    position: relative;
    width: auto;
    >h1{
        font-family: "Libre Franklin", Helvetica, Arial, sans-serif;
        line-height: 1.33333;
        font-size: 55px;
        font-weight: 200;
        color: #424242;
        word-break: break-word;
        >span{
            font-size: 100%;
            color: $primary;
        }
    }
    >div{
        display: inline-block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 2px;
        background-color: $secondary;
    }
}

div.inline_icons{
    @include block_ctrl();
    >div{
        @include block_ctrl();
        width: 33.333%;
        >div{
            display: inline-block;
            position: absolute;
            width: 50px;
            height: auto;
            top: 0px;
            bottom: 0px;
            left: 15px;
            margin: auto 0px;
            border-radius: 50%;
            >img{
                display: inline-block;
                position: absolute;
                width: 100%;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                margin: auto;
            }
        }
        >span{
            @include block_ctrl();
            padding-left: 75px;
            >strong{
                font-size: 75%;
            }
        }
    }
}

nav.cs_01{
    >div.breadcrumb{
        background-color: rgba(#FFFFFF, .9);
        margin: 0px;
        padding: 5px 15px;
        border-radius: 0px;
        .breadcrumb-item{
            color: $primary;
            &:before{
                color: $primary;
            }
            &.active{
                color: darken($secondary, 10%);
                &:before{
                    color: darken($secondary, 10%);
                }
            }
        }
    }
}


#navbar-main{
    padding: 5px 0px;
    background-color: #FFFFFF !important;
    top: 30px;
    .navbar-brand img {
        height: 75px;
    }  
    &.navbar-transparent {
        &.solid{
            background-color: #FFFFFF !important;
        }
        &.headroom--not-top{
            background-color: #FFFFFF !important;
        }
    }
    div.navbar-collapse{
        >ul.navbar-nav-hover{
            >li.dropdown{
                >a{
                    >i{

                    }
                    >span{
                        color: $primary;
                    }
                }
            }
        }
        >ul.align-items-lg-center{
            >li{
                >a{
                    >i{
                        color: $primary;
                    }
                    >span{
                        color: $primary;
                    }
                    &.in{
                        >i{
                            font-size: 25px;
                            color: $tertiary;
                        }
                    }
                }
            }
        }
    }
}

a.btn.btn-outline-primary{
    &:hover{
        color: #FFFFFF! important;
        >span{
             color: #FFFFFF! important;
        }
    }
}

div.topmain{
    background-size: cover;
    background-position: center;
    height: 100vh;
    min-height: 590px;
    position: relative;
    overflow: hidden;
    &:before{
    	content: ' ';
    	position: absolute;
    	top: 0;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	z-index: 2;
   	    background-color: $tertiary !important;
   	    opacity: 0.25;
    	pointer-events: none;
    	transition: ease opacity 300ms 100ms;
    }
    >div.section-angled-layer-bottom{
    	padding: 5rem 0;
    	transform: skewY(-4.7deg) translate3d(0, 50%, 0);
    	z-index: 99 !important;
    	position: absolute;
    	bottom: 0;
    	left: 0;
    	width: 100%;
    }
    >.row{
    	z-index: 5;
    	position: relative;
    }
    a.ease_vertical{
    	@include block_ctrl();
    	float: none;
    	margin: 0px auto;
    	width: 50px;
    	margin-top: 3rem;
    	position: absolute;
    	left: 0px;
    	right: 0px;
    	>img{
    		@include block_ctrl();
            cursor: pointer;
    	}
    	-webkit-animation: arrow_fade_move_down 2s ease-in-out infinite;
    	-moz-animation: arrow_fade_move_down 2s ease-in-out infinite;
    	-ms-animation: arrow_fade_move_down 2s ease-in-out infinite;
    	animation: arrow_fade_move_down 2s ease-in-out infinite;
    }
}

.navbar-nav .nav-link {
    font-weight: 600;
}

div.dropdown-menu-inner{
    >a.media{
        >div.icon{
            position: relative;
            >img{
                @include absolute_center();
                width: 30px;
                height: 30px;
            }
        }
    }
}

div.divider{
    background-color: $secondary;
    height: 1px;
    &.grey{
        background-color: #DDDDDD;
    }
}

div.inline_tipos{
    >div.form-check{
        padding: 0px;
        margin-bottom: 2px;
        >label.form-check-label{
            >input.form-check-input{
                margin-top: 4px;
                margin-left: 4px;
                width: 15px;
                height: 15px;
            }
        }
    }
}

@-webkit-keyframes arrow_fade_move_down{
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
    100% { transform: translateY(0); }
}

@-ms-keyframes arrow_fade_move_down{
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
    100% { transform: translateY(0); }
}

@-moz-keyframes arrow_fade_move_down{
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
    100% { transform: translateY(0); }
}

@keyframes arrow_fade_move_down{
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
    100% { transform: translateY(0); }
}

a.item_add{
    display: inline-block;
    position: relative;
    float: none;
    width: 320px;
    height: 320px;
    margin: auto 0px;
    overflow: hidden;
    max-width: 95%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    &.sm{
        width: 260px;
        height: 260px;
    }
    >img{
        @include ease(.3s);
        display: inline-block;
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        height: 100%;
        width: auto;
        left: 0px;
        right: 0px;
        float: none;
    }
    >span.thumb-info-title{
        transition: all 0.3s;
        background: rgba(33, 37, 41, 0.5);
        color: #FFF;
        font-weight: 600;
        left: 0;
        letter-spacing: -.05em;
        position: absolute;
        z-index: 2;
        max-width: 90%;
        font-size: 17px;
        padding: 13px 21px 2px;
        bottom: 13%;
        >span.thumb-info-inner{
            @include block_ctrl();
            padding-bottom: 15px;
        }
        >span.thumb-info-type{
            background-color: $tertiary;
            border-radius: 2px;
            display: inline-block;
            position: absolute;
            float: left;
            font-size: 0.6em;
            font-weight: 600;
            letter-spacing: 0;
            margin: 8px -2px -28px 0px;
            padding: 2px 12px;
            text-transform: uppercase;
            z-index: 2;
            line-height: 2.3;
            color: #FFFFFF;
            left: 15px;
            bottom: 10px;
        }
    }
    >span.thumb-info-action{
        @include ease(.3s);
        background: rgba(#000000, .6);
        border-radius: 0 0 0 4px;
        display: inline-block;
        font-size: 25px;
        height: 50px;
        line-height: 50px;
        position: absolute;
        right: -100px;
        text-align: center;
        top: -100px;
        width: 50px;
        z-index: 2;
        >span.thumb-info-action-icon{
            >img{
                display: inline-block;
                position: absolute;
                left: 0px;
                right: 0px;
                top: 0px;
                bottom: 0px;
                height: 5px;
                width: 5px;
                margin: auto;
                @include ease(.4s);
            }
        }
    }
    >span.overlay{
        @include absolute_center();
        opacity: 0;
        pointer-events: none;
        background: rgba(#000000, .35);
        @include ease(.3s);
    }
    &:hover{
        >img{
            transform: scale(1.1, 1.1);
        }
        >span.thumb-info-title{
            background: rgba(33, 37, 41, 0.9);
        }
        >span.thumb-info-action{
            right: 0;
            top: 0;
            img{
                height: 20px !important;
                width: 20px !important;
            }
        }
        >span.overlay{
            opacity: 1;
        }
    }
}

div.topBar{
    text-align: center;
    span{
        color: #FFFFFF;
    }
    a{
        padding: 3px 12px;
        position: relative;
        display: inline-block;
        color: #FFFFFF;
        @include ease(.25s);
        &:hover{
            background-color: #FFFFFF;
            color: $primary;
        }
    }
}

.headroom--not-top {
    top: 0px !important;
}

div.MuiContainer-root.MuiContainer-maxWidthSm{
    box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 0 6px 6px rgba(0, 0, 0, .23);
    padding: 30px;
}

div.selectUserType{
    @include block_ctrl();
    >div{
        @include block_ctrl();
        width: 50%;
        text-align: center;
        padding: 15px 30px;
        >a{
            @include block_ctrl();
            box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
            padding: 30px 15px;
            >img{
                display: inline-block;
                position: relative;
                margin: 0px auto;
                width: 100px;
                padding-bottom: 5px;
                transform: scale(1);
                @include ease(.25s); 
            }
            >span{
                @include block_ctrl();
                text-align: center;
                color: $secondary;
                @include ease(.25s); 
                >strong{
                }
            }
            &:hover{
                >img{
                    transform: scale(1.1); 
                }
                >span{
                    color: $primary;
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) { 

}
@include media-breakpoint-down(sm) { 
    div.title_one > h1 {
        font-size: 38px;
    }
}
@include media-breakpoint-down(md) { 
    .dropdown-menu.show {
        opacity: 1 !important;
        >div.dropdown-menu-inner{
            >a.media{
                margin-bottom: 1rem;
                >div.icon{
                    display: none;
                    >img{

                    }
                }
                >div.media-body{
                    >h6{

                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(lg) { 

}
@include media-breakpoint-down(xl) { 

}