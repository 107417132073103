@import "./helper";

@import "../../../node_modules/bootstrap/scss/functions";

@import "../argon_theme/scss/argon-design-system/variables";
$primary: #ff0000;
$secondary: #0a4d97;
$tertiary: #BF0000;

div.navTabs{
	@include block_ctrl();
	>ul{
		@include block_ctrl();
		>li{
			@include block_ctrl();
			padding: 7px 15px;
			text-align: center;
			>a{
				@include block_ctrl();
    			padding: 10px 0px;
    			background-color: $tertiary;
    			color: #FFFFFF;
    			font-family: "Roboto", "Helvetica", "Arial", sans-serif;
   			    letter-spacing: 0px;
   			    border-bottom: 2px solid $tertiary;
    			@include ease(.15s);
    			&:before{
    				content: '';
    				display: inline-block;
    				position: absolute;
    				left: 7px;
    				top: 0px;
    				bottom: -2px;
    				margin: auto 0px;
    				width: 5px;
    				background-color: transparent;
    				pointer-events: none;
    				@include ease(.15s);
    			}
				&:hover{
				    letter-spacing: .5px;
				    border-color: #000000;
				    background-color: rgba($tertiary, .9);
				}
				&.active{
				    letter-spacing: .5px;
				    border-color: #000000;
				    &:before{
				    	background-color: #000000;
				    }
				}
			}
		}
	}
}

div.dropzone_{
    border-radius: 2px;
    border: 2px dashed #eaeaea;
    padding: 25px 0px;
    text-align: center;
}
.rootcard{
	width: 100%;
	.mediacard{
		height: 150px;
		.buttonCard{
    		display: inline-block;
    		position: relative;
    		float: right;
    		padding: 0px;
    		margin: 0px;
    		top: 5px;
    		right: 5px;
			.svgButtonCard{
				color: $tertiary;
			}
		}
	}
}

div.item_upload{
	@include block_ctrl();
	text-align: center;

	>div.img_prev{
		@include block_ctrl();
		width: 100%;
		height: 200px;
		border: 1px solid #DDDDDD;
		border-radius: 2px;
    	margin: 0px auto;
    	float: none;
		>img{
			display: inline-block;
			position: absolute;
    		height: auto;
    		width: auto;
    		max-width: 100%;
    		max-height: 100%;
    		margin: auto;
			top: 0px;
			bottom: 0px;
			left: 0px;
			right: 0px;
		}
	}
	>input{
		display: none;
	}
	>label{
		&.disabled{
			pointer-events: none;
		}
		>button{

		}
	}
}
header.MuiAppBar-root{
	>div.MuiToolbar-root{
		text-align: center;
		>a{
			float: none;
	    	margin: 0px auto;
			>img{
				width: 95px;
			}
		}
	}
}
ul.listSpecs{
	>li{
	    margin: 5px 0px;
		>svg{
			margin-right: 5px;
    		bottom: 2px;
    		position: relative;
		}
	}
}



.dialog_datepidker{
	.datepick_st{
	    z-index: 9;
		display: inline-block;
    	float: left;
    	width: 100%;
    	height: auto;
		.react-datepicker-wrapper {
		    width: 100%;
			input{
			    width: 100%;
			    height: 56px;
			    display: inline-block;
			    position: relative;
			    letter-spacing: inherit;
			    border: 1px solid rgba(0, 0, 0, 0.23);
			    border-radius: 4px;
			    padding: 18.5px 14px;
			}
		}
		div.react-datepicker__triangle {
		    margin-left: -20px !important;
		}
	}
	.MuiDialog-paperScrollPaper{
		overflow: auto;
		div.MuiDialogContent-root{
			overflow: hidden;
			>.MuiGrid-container {
			    display: inline-block;
			}
		}
	}
}

.calendar .months-container .month-container.month-3 {
    height: 225px;
}

p.error{
	display: block;
	position: relative;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
	color: #f44336;
}

div.item_slide_type{
	padding: 30px;
	>div{
		position: relative;
		height: 240px;
	    box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
	    border-radius: 5px;
    	overflow: hidden;
		>div{
			padding-right: 240px;
    		margin: 0px;
    		height: 100%;
		}
	}
	span{
		@include block_ctrl();
    	color: #424242;
    	font-size: 1.1rem;
    	font-weight: 700;
    	padding-bottom: 15px;
	}
	img{
		display: inline-block;
		position: absolute;
		right: 0px;
		top: 0px;
		bottom: 0px;
		width: 240px;
		height: 240px;
		margin: auto;
	}
}


a.btn_custom,button.btn_custom{
	border: 1px solid #DDDDDD;
	color: #FFFFFF;
	font-size: .9rem;
	&.btn_google{
		background-color: $google-plus;
	}
	&.btn_facebook{
		background-color: $facebook;
	}
	i.fa{
		font-size: 1.1rem;
		top: 0px;
    	position: relative;
	}
	&.desync{
		opacity: .55;
	}
	&.on_register{
		width: 50%;
		img{
			width: 25px;
			position: relative;
			margin-right: 7px;
			top: 0px;
		}
		&:hover{
			span{
				color: #FFFFFF;
			}
		}
	}
}

div.container_pagination{
	@include block_ctrl();
	text-align: right;
	padding: 30px 15px;
	>nav.MuiPagination-root{
		display: inline-block;
		position: relative;
		float: right;
		width: auto;
		>{
			ul{
				button.MuiPaginationItem-page{
					&.Mui-selected{
						background-color: $primary;
						color: #FFFFFF;
					}
				}
			}
		}
	}
}

div.absoluteLoader{
	@include absolute_center();
	background-color: #FFFFFF;
	z-index: 3;
	>div.spinner-border{
		@include absolute_center();
    	width: 50px;
    	height: 50px;
	}
}